import React from "react";
import questlist from "../../../assets/images/quest-list.png";

const ElvenQuest = () => {
  return (
    <div className="elven-quest">
      <div className="elven-bg"></div>
      <div className="quest-container">
        <div className="quest-caption">
          <h1>Elven Quests</h1>
          <span>
            Earn $HART (HARA Token) as your Elves complete the Quests. Some
            Quest will generate more $HART but requires more Elf. Choose your
            path wisely!
          </span>
        </div>
        <div className="quest-list">
          <img src={questlist} alt="Elven Quest" />
        </div>
      </div>
    </div>
  );
};

export default ElvenQuest;
