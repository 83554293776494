import opensealogo from "../../../assets/logo/opensea-logo.svg";
import discordlogo from "../../../assets/logo/discord-logo.svg";
import twitterlogo from "../../../assets/logo/twitter-logo.svg";
import instagramlogo from "../../../assets/logo/instagram-logo.svg";
import rarity from "../../../assets/images/rarity.png";

const SosmedButton = () => {
  return (
    <div className="sosmed">
      {/* Opensea */}
      <div className="sosmed-btn">
        <a
          href="https://opensea.io/collection/metaforestsociety"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={opensealogo} alt="Opensea" />
        </a>
      </div>

      {/* Discord */}
      <div className="sosmed-btn">
        <a
          href="https://discord.gg/metaforestsociety"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={discordlogo} alt="Discord" />
        </a>
      </div>

      {/* Twitter */}
      <div className="sosmed-btn">
        <a
          href="https://twitter.com/metaforestsoc"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitterlogo} alt="Twitter" />
        </a>
      </div>

      {/* Instagram */}
      <div className="sosmed-btn">
        <a
          href="https://instagram.com/metaforestsociety"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagramlogo} alt="Instagram" />
        </a>
      </div>

      {/* Rarity */}
      <div className="sosmed-btn">
        <a
          href="https://raritysniper.com/meta-forest-society"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={rarity} alt="Instagram" />
        </a>
      </div>
    </div>
  );
};

export default SosmedButton;
