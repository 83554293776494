import React from "react";

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <h3 className="page-not-found__code">404</h3>
      <h3 className="page-not-found__caption">Page Not Found</h3>
    </div>
  );
};

export default PageNotFound;
