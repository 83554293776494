import React from "react";
import { NavLink } from "react-router-dom";

const network = process.env.REACT_APP_CHARID;
const femaleURL = process.env.REACT_APP_FEMALE_URL;

const NavbarList = () => {
  return (
    <nav className="navbar-list">
      <NavLink to="/">Home</NavLink>
      <a
        href={
          network === "1"
            ? "https://foresthall.metaforestsociety.xyz"
            : network === "4"
            ? "https://devdapps.metaforestsociety.xyz"
            : "https://stgdapps.metaforestsociety.xyz"
        }
      >
        Forest Hall
      </a>
      <NavLink to="/book-of-elves">Book of Elves</NavLink>
      <a href={femaleURL}>Claim Female Elves</a>
    </nav>
  );
};

export default NavbarList;
