import React from "react";
import enchantedpack from "../../../assets/images/enchanted-pack.png";
import enchantedpackm from "../../../assets/images/enchanted-pack-mobile.png";

const Potion = () => {
  return (
    <div className="potion">
      <div className="potion-container">
        <div className="potion-caption">
          <h1>Enchant Your Elves with Potions</h1>
          <span>
            Trade your $HART with special potions that can enchant your Elves to
            the next level. Earn more $HART as your Enchanted Elves complete the
            Quests you sent them to.
          </span>
        </div>
        <div className="potion-ilustration">
          <img src={enchantedpack} alt="Elf Enchanted" />
        </div>
        <div className="potion-ilustration-mobile">
          <img src={enchantedpackm} alt="Elf Enchanted" />
        </div>
      </div>
    </div>
  );
};

export default Potion;
