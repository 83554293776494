import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer } from "antd";
// import {
//   discordLogo,
//   openseaLogo,
//   instagramLogo,
//   rarityIcon,
//   twitterLogo,
// } from "../../../assets";
// import MintBtn from "../../atoms/mint-btn/MintBtn";
import discordLogo from "../../../assets/logo/discord-logo.svg";
import openseaLogo from "../../../assets/logo/opensea-logo.svg";
import instagramLogo from "../../../assets/logo/instagram-logo.svg";
import twitterLogo from "../../../assets/logo/twitter-logo.svg";
import rarity from "../../../assets/images/rarity.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseOutline } from "react-icons/io5";
import Button from "../../atoms/button/Button";

const DrawerMenu = () => {
  const [visible, setVisible] = useState(false);
  const MINTING_URL = process.env.REACT_APP_MINTING_URL;
  const network = process.env.REACT_APP_CHARID;
  const femaleURL = process.env.REACT_APP_FEMALE_URL;

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <GiHamburgerMenu
        onClick={showDrawer}
        style={{ height: 25, width: 25, color: "white" }}
      />
      <Drawer
        placement="top"
        onClose={onClose}
        visible={visible}
        closable={false}
        height="100%"
      >
        <div className="drawerMenu">
          <IoCloseOutline className="close-drawer" onClick={onClose} />
          <div className="drawerMenu--inner">
            <div className="drawerMenu--list">
              <Link
                to="/"
                // style={navLinkStyles}
                className="nav-link-drawer"
              >
                <div>Home</div>
              </Link>
              <a
                href={
                  network === "1"
                    ? "https://foresthall.metaforestsociety.xyz"
                    : network === "4"
                    ? "https://devdapps.metaforestsociety.xyz"
                    : "https://stgdapps.metaforestsociety.xyz"
                }
                // style={navLinkStyles}
                className="nav-link-drawer"
              >
                <div>Forest Hall</div>
              </a>
              <Link
                to="/book-of-elves"
                // style={navLinkStyles}
                className="nav-link-drawer"
              >
                <div>Book of Elves</div>
              </Link>
              <a
                href={femaleURL}
                // style={navLinkStyles}
                className="nav-link-drawer"
              >
                <div>Claim Female Elves</div>
              </a>
            </div>

            <div className="mint-drawer">
              <a href={MINTING_URL} target="_blank" rel="noopener noreferrer">
                <Button className="mint-drawer__mint">Buy on OpenSea</Button>
              </a>
            </div>
          </div>

          <div className="drawer-bottom">
            {/* Social Media Button Drawer */}
            <div className="socmed-container">
              <div className="socmed-btn">
                <a
                  href="https://opensea.io/collection/metaforestsociety"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={openseaLogo} alt="" />
                </a>
              </div>
              <div className="socmed-btn">
                <a
                  href="https://discord.gg/metaforestsociety"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={discordLogo} alt="" />
                </a>
              </div>
              <div className="socmed-btn">
                <a
                  href="https://twitter.com/metaforestsoc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitterLogo} alt="" />
                </a>
              </div>
              <div className="socmed-btn">
                <a
                  href="https://instagram.com/metaforestsociety"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagramLogo} alt="" />
                </a>
              </div>
              <div className="socmed-btn">
                <a
                  href="https://raritysniper.com/meta-forest-society"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={rarity} alt="Rarity" />
                </a>
              </div>
            </div>
            <span className="cr-drawer">
              &copy; 2021 Meta Forest Society. All Rights Reserved.
            </span>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerMenu;
