import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { BsCheckLg } from "react-icons/bs";
import mounttl from "../../../assets/images/mt-1a.png";

const LaunchMilestonesM = () => {
  const launchMilestonesList = [
    { progress: "10%", stats: "1", desc: ["1 ETH Giveaway to Holders"] },
    {
      progress: "25%",
      stats: "1",
      desc: [
        "2 ETH Giveaway to Holders",
        "Meta Forest Society - Special Artwork Hoodie & T-Shirt Giveaways to 100 lucky holders. Wallets will be snapshotted and raffled",
      ],
    },
    {
      progress: "50%",
      stats: "0",
      desc: [
        "HARA initiative launched - Begin delivering the ginger seeds that we’ve collected from the sale to women farmers. We’ll update the progress on our discord & private dashboard for NFT holders",
      ],
    },
    {
      progress: "75%",
      stats: "0",
      desc: [
        "4 ETH Total Giveaway to Holders",
        "Begin partnering with Eco-Friendly & Sustainable Brands to give our holders benefit and special access from selected brands ",
      ],
    },
    {
      progress: "100%",
      stats: "0",
      desc: [
        "We'll start the 3,636 Female Elves Collection. Can be minted for free (0 ETH + Gas) if you hold  MFS NFT in your wallet",
        "10 ETH Community Wallet Created. Every usage from this wallet will be voted by our Holders and Community",
      ],
    },
  ];

  return (
    <div className="launch-milestones-m">
      <div className="milestones-container-m">
        <h1>Launch Milestones</h1>
        <VerticalTimeline
          lineColor="linear-gradient(5deg, #D4E5AE 50%, #6DF0C1 66.43%)"
          layout="1-column-left"
          animate={false}
          className="timeline"
        >
          {launchMilestonesList.map(({ progress, stats, desc }, index) => {
            return progress === "10%" && stats === "1" ? (
              <VerticalTimelineElement
                key={index + progress}
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "unset",
                  color: "#fff",
                  boxShadow: "none",
                  marginTop: "0",
                }}
                contentArrowStyle={{ borderRight: "#191437" }}
                iconStyle={{
                  background:
                    "linear-gradient(100.66deg, #C4EA6E 23.41%, #6DF0C1 66.43%)",
                  color: "#191437",
                  boxShadow: "none",
                }}
                icon={<BsCheckLg />}
                position="right"
              >
                <div className="lms-box-checked">
                  <div className="progress">
                    <h1>{progress}</h1>
                    <h1 className="sold">Sold</h1>
                  </div>
                  <div className="lms-desc">
                    {desc.map((d, index) => (
                      <span
                        key={index + d}
                        style={{
                          textDecoration: "line-through",
                          textDecorationColor: "#999999",
                        }}
                      >
                        {d}
                      </span>
                    ))}
                  </div>
                </div>
              </VerticalTimelineElement>
            ) : stats === "1" ? (
              <VerticalTimelineElement
                key={index + progress}
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "unset",
                  color: "#fff",
                  boxShadow: "none",
                  marginTop: "0",
                }}
                contentArrowStyle={{ borderRight: "#191437" }}
                iconStyle={{
                  background:
                    "linear-gradient(100.66deg, #C4EA6E 23.41%, #6DF0C1 66.43%)",
                  color: "#191437",
                  boxShadow: "none",
                }}
                icon={<BsCheckLg />}
                position="right"
              >
                <div className="lms-box-checked">
                  <div className="progress">
                    <h1>{progress}</h1>
                    <h1 className="sold">Sold</h1>
                  </div>
                  <div className="lms-desc">
                    {desc.map((d, index) => (
                      <span key={index + d}>{d}</span>
                    ))}
                  </div>
                </div>
              </VerticalTimelineElement>
            ) : (
              <VerticalTimelineElement
                key={index}
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "unset",
                  color: "#fff",
                  boxShadow: "none",
                  marginTop: "0",
                }}
                contentArrowStyle={{ borderRight: "#191437" }}
                iconStyle={{
                  background: "#D4E5AE",
                  color: "#191437",
                  boxShadow: "none",
                  fontSize: "1000px",
                }}
                position="right"
              >
                <div className="lms-box-unchecked">
                  <div className="progress">
                    <h1>{progress}</h1>
                    <h1 className="sold">Sold</h1>
                  </div>
                  <div className="lms-desc">
                    {desc.map((d, index) => (
                      <span key={index + d}>{d}</span>
                    ))}
                  </div>
                </div>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
      <div className="lm-decor-m">
        <div className="decor tl">
          <img src={mounttl} alt="line" />
        </div>
      </div>
    </div>
  );
};

export default LaunchMilestonesM;
