import React from "react";
import Button from "../../atoms/button/Button";

const ElvesSwitch = ({ showFemale, handleChangeParamsFemale }) => {
  return (
    <div className="elves-switch">
      <Button
        type={showFemale === "true" ? "MFS" : "NUDE"}
        className="elves-switch__female switch"
        value={true}
        onClick={(e) => handleChangeParamsFemale(e)}
      >
        Female
      </Button>
      <Button
        type={showFemale === "true" ? "NUDE" : "MFS"}
        className="elves-switch__male switch"
        value={false}
        onClick={(e) => handleChangeParamsFemale(e)}
      >
        Male
      </Button>
    </div>
  );
};

export default ElvesSwitch;
