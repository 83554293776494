import React, { useState } from "react";
import { Drawer } from "antd";
import filterIcon from "../../../assets/logo/filter-icon.svg";
import { IoCloseOutline } from "react-icons/io5";
import ElvesSwitch from "../../molecules/elves-switch/ElvesSwitch";
import AccordionFilter from "../../molecules/accordion/AccordionFilter";
import Footer from "../../organisms/footer/Footer";

const DrawerFilter = ({
  showFemale,
  traits,
  handleChangeParamsFemale,
  handleChangeParamsFilter,
  showFilter,
}) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="drawer-filter" onClick={showDrawer}>
        <img src={filterIcon} alt="" /> Filter
      </div>
      <Drawer
        placement="top"
        onClose={onClose}
        visible={visible}
        closable={false}
        height="100%"
      >
        <div className="drawerMenu">
          <IoCloseOutline className="close-drawer" onClick={onClose} />
          <div className="drawerMenu__inner">
            <span className="drawerMenu__filter-title">Filter</span>
            <ElvesSwitch
              showFemale={showFemale}
              handleChangeParamsFemale={handleChangeParamsFemale}
            />
            <div style={{ marginBottom: 30 }}>
              <AccordionFilter
                traits={traits}
                handleChangeParamsFilter={handleChangeParamsFilter}
                showFilter={showFilter}
              />
            </div>
          </div>
          <Footer />
        </div>
      </Drawer>
    </>
  );
};

export default DrawerFilter;
