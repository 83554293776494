import React, { useEffect } from "react";
import ElvenQuest from "../components/organisms/elven-quest/ElvenQuest";
import ElvenTeam from "../components/organisms/elven-team/ElvenTeam";
import ElvenSlide from "../components/organisms/elven-team/ElvenSlide";
import FAQ from "../components/organisms/faq/FAQ";
import Footer from "../components/organisms/footer/Footer";
import ForestCreatures from "../components/organisms/forest-creatures/ForestCreatures";
import Hara from "../components/organisms/hara/Hara";
import HaraMobile from "../components/organisms/hara/HaraMobile";
import Hero from "../components/organisms/hero/Hero";
import Kolase from "../components/organisms/kolase/Kolase";
import LaunchMilestones from "../components/organisms/launch-milestones/LaunchMilestones";
import LaunchMilestonesM from "../components/organisms/launch-milestones/LaunchMilestonesM";
import Lore from "../components/organisms/lore/Lore";
import Navbar from "../components/organisms/navbar/Navbar";
import Potion from "../components/organisms/potion/Potion";

const LandingPage = () => {
  useEffect(() => {
    sessionStorage.removeItem("MFS-page");
    sessionStorage.removeItem("MFS-sort");
  }, []);

  return (
    <>
      <Navbar />
      <Hero />
      <Lore />
      <Kolase />
      <LaunchMilestones />
      <LaunchMilestonesM />
      <div className="transition-section"></div>
      <ElvenQuest />
      <div className="questbg-mobile"></div>
      <Potion />
      <ForestCreatures />
      <Hara />
      <HaraMobile />
      <ElvenTeam />
      <ElvenSlide />
      <FAQ />
      <Footer />
    </>
  );
};

export default LandingPage;
