import React from "react";
import loreimg from "../../../assets/images/the-lore-image.png";

const Lore = () => {
  return (
    <div className="lore">
      <div className="lore-content">
        <div className="lore-img">
          <img src={loreimg} alt="Lore" />
        </div>
        <div className="lore-story">
          <h1 className="lore-story__title">The Lore</h1>
          <span>
            Amongst the ancient races that resides over the nine realms of the
            Yggdrasil tree, lived the Elven species. Blessed by the divine
            prowess that was bestowed to their ancestors, the Eldars, by Freyja
            herself, the elves became a pillar that served to sustain the very
            life force of Yggdrasil itself.
          </span>
          <span>
            However, a certain cataclysm occurred 3500 years ago, which not only
            saw the near extinction of the elven race, but also saw the
            Yggdrasil tree stripped completely off of its life force. In a
            desperate attempt to salvage any saving grace, the last of the
            Eldars orchestrated a mass exodus of the remaining elven children to
            Midgard, bringing with them a “seed” that has yet to have its
            purpose revealed.
          </span>
          <span>
            The elves then cultivated life alongside the humans of Midgard,
            where the seed continued to take its roots in guiding both the elves
            and humans, to a much more prosperous life.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Lore;
