import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL
const MFSToken = process.env.REACT_APP_MY_MFS_TOKEN


// Get All Elves
export const allElves = (isFemale = false, isDsc = true, page = 1, per_page = 9, filter = [], find = []) => {
  const data = {
    isFemale,
    isDsc,
    page,
    per_page,
    filter,
    find
  }
  return axios({
    method: 'POST',
    url: `${BASE_URL}/elves/allGallery`,
    headers: {
      Authorization: `Bearer ${MFSToken}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data)
  })
}

// Get Elf Detail
export const elfDetail = (asset_contract_address, token_id) => {
  return axios({
    method: 'GET',
    url: `https://testnets-api.opensea.io/api/v1/asset/${asset_contract_address}/${token_id}/`,
    headers: {
      Authorization: `Bearer ${MFSToken}`,
      "Content-Type": "application/json",
    }
  })
}

// Search Elf Pair
export const elfPair = (contractAddr = "0x59fbf23c33Cc7F9e961207aab085B850857e02a7", tokenIds) => {
  const data = {
    contractAddr,
    tokenIds
  }
  return axios({
    method: 'POST',
    url: `${BASE_URL}/elves/searchElvesPair`,
    headers: {
      Authorization: `Bearer ${MFSToken}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data)
  })
}


// Get Contract Elves Gender
export const elvesContract = () => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/general/test`,
    headers: {
      Authorization: `Bearer ${MFSToken}`,
      "Content-Type": "application/json",
    }
  })
}


// Get Traits
export const getTraits = (contractAddr = "0x59fbf23c33Cc7F9e961207aab085B850857e02a7") => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/elves/traits?contractAddr=${contractAddr}`,
    headers: {
      Authorization: `Bearer ${MFSToken}`,
      "Content-Type": "application/json",
    }
  })
}