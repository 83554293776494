import React from "react";
import mounttl from "../../../assets/images/mt-1a.png";
import mounttr from "../../../assets/images/mt-1b.png";
import mountbl from "../../../assets/images/mt-3.png";
import mountbr from "../../../assets/images/mt-2.png";
import AccordionFAQ from "../../molecules/accordion/AccordionFAQ";

const FAQ = () => {
  return (
    <div className="faq">
      <div className="faq-container">
        <div className="faq-subcontainer">
          <h1>Frequently Asked Questions</h1>
          <AccordionFAQ />
        </div>
      </div>
      <div className="lm-decor">
        <div className="decor tl">
          <img src={mounttl} alt="line" />
        </div>
        <div className="decor tr">
          <img src={mounttr} alt="line" />
        </div>
        <div className="decor bl">
          <img src={mountbl} alt="line" />
        </div>
        <div className="decor br">
          <img src={mountbr} alt="line" />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
