import React from "react";

const Button = ({ children, type, className, onClick, disabled, value }) => {
  const BUTTON_COLOR = {
    MFS: "btn__mfs",
    DARK: "btn__dark",
    OUTLINE: "btn__outline",
    NUDE: "btn__nude",
    KILL: "btn__kill",
  };

  const colorClassName = BUTTON_COLOR[type || "MFS"];
  const buttonClassName = [className || ""];

  return (
    <button
      className={"btn " + colorClassName + " " + buttonClassName}
      onClick={onClick}
      disabled={disabled}
      value={value}
    >
      {children}
    </button>
  );
};

export default Button;
