import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import SocmedTeams from "../sosmed-team/SocmedTeams";

SwiperCore.use([Pagination]);

const ElvenSlideTeam = () => {
  const teamList = [
    // {
    //     image: "/images/pfp_tiff.png", name: "Tiffany Setiadharma", jobTitle: "Program Manager",
    //     jobDesc: ["An avid tech, blockchain, NFT, DeFi & DAO enthusiast who believes that the tech industry can be leveraged as a solution to tackle real world problems.", "A social impact advocate who has had experiences working with NGO's such as Indonesian Global Compact Network, and co-founder of her own women empowerment movement such as Project JAWAB & Girl Effect Indonesia."],
    //     socmed: [{ name: "twitter", link: "https://twitter.com/tiffstdhrma_eth" }, { name: "instagram", link: "https://www.instagram.com/tiffanysetiadharma/" }]
    // },
    {
      image: "/images/pfp_mett.png",
      name: "met mangindaan",
      jobTitle: "Lead Artist",
      jobDesc: [
        "Indonesian Illustrator, chocolate lover, devoted Gooner, Vespa lover. Born and raised in Jakarta and now living in Bali.",
        "Working with various clients from Chevron, Isuzu, CIMB Niaga, United Tractor, XL, etc. to some silly nerdy projects.",
      ],
      socmed: [
        { name: "twitter", link: "https://twitter.com/metmangindaan" },
        { name: "instagram", link: "https://www.instagram.com/metmangindaan/" },
      ],
    },
    // {
    //     image: "/images/pfp_arr.png", name: "ARA", jobTitle: "Marketing & Community Development",
    //     jobDesc: ["A marketing scholar with 9 years of experience in digital marketing agency, NGO and social movements, delivering impact to dozens of clients from various industries. Believes the blockchain technology will reshape the future of marketing and humanity at large."],
    //     socmed: [{ name: "twitter", link: "https://twitter.com/aradityarian" }, { name: "instagram", link: "https://www.instagram.com/aradityarian/" }, { name: "discord", link: "ARA#7295" }]
    // },
    {
      image: "/images/pfp_andr.png",
      name: "Andre Dubari",
      jobTitle: "Lore Master",
      jobDesc: [
        "A proud storytelling & gamification enthusiast. Always eager to bring out the best in people and design socially impactful activities through joyful experiences and powerful narratives.",
      ],
      socmed: [
        { name: "twitter", link: "https://twitter.com/andredubari" },
        { name: "instagram", link: "https://instagram.com/andredubari" },
      ],
    },

    {
      image: "/images/pfp_fal.png",
      name: "Naufal Ikhsan",
      jobTitle: "Engineer",
      jobDesc: [
        "Educated along with the rapid development of technologies & Enthusiastic in having a broad impact on society. Experienced in the world of work ranging from IT freelancer, algorithmic trading to blockchain development in enterprise. ",
      ],
      socmed: [
        { name: "twitter", link: "https://twitter.com/naufalikhsan85" },
        {
          name: "instagram",
          link: "https://www.instagram.com/naufalikhsan75/",
        },
      ],
    },
    {
      image: "/images/pfp_arvv.png",
      name: "Arva",
      jobTitle: "Project Manager",
      jobDesc: [
        "A blockchain & tech enthusiast, spends most of his time tinkering with code and trying to make art. A creative coder who believes that blockchain and NFT could change the world in a better way. ",
      ],
      socmed: [
        { name: "twitter", link: "https://twitter.com/heyyarva" },
        { name: "instagram", link: "https://instagram.com/heyyarva" },
      ],
    },
    {
      image: "/images/pfp-andrew.png",
      name: "Andrew ",
      jobTitle: "Discord Mod",
      jobDesc: [
        "An NFT Enthusiast who spends most of his time lurking in the shadows and researching new projects and communities.",
        "Currently studying to be an entrepreneur and also taking a few interesting side jobs in the NFT world.",
        "Love building and interacting with the community and appreciate all the rangers and forest dwellers of the MFS community!",
      ],
      socmed: [
        { name: "twitter", link: "https://twitter.com/Drewwtanto" },
        { name: "instagram", link: "https://www.instagram.com/andrew.sutanto" },
      ],
    },
    {
      image: "/images/pfp-james.png",
      name: "James Barlian",
      jobTitle: "Discord Mod",
      jobDesc: [
        "A fellow Degen, whose passion is to connect and build relationships with artists and collectors in the NFT Space.",
        "Having a computer science background with knowledge of blockchain technology, I fully believe that Cryptocurrency and NFTs are here to stay.",
        "Very Community oriented when it comes to dealing with fellow Degens in the NFT Space, because without a Community, an NFT project will not sustain for the long term.",
      ],
      socmed: [
        { name: "twitter", link: "https://twitter.com/Jamenoodle" },
        { name: "instagram", link: " https://www.instagram.com/jamenoodle" },
      ],
    },
  ];

  return (
    <Swiper
      pagination={true}
      className="mySwiper"
      style={{ height: "100%", width: "100vw" }}
    >
      {teamList.map((data, index) => (
        <SwiperSlide key={index + data} className="elvenbox">
          <img src={data.image} alt={data.name} className="elvenpic" />
          <h3>{data.name}</h3>
          <h4 style={{ fontWeight: "500" }}>{data.jobTitle}</h4>
          {data.jobDesc.map((jd, index) => (
            <div
              key={index}
              style={{ lineHeight: "19.6px", marginTop: "8px" }}
              className="elvendesc"
            >
              {jd}
            </div>
          ))}
          <SocmedTeams data={data?.socmed} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ElvenSlideTeam;
