import React from "react";
import MFSLogo from "../../atoms/mfs-logo/MFSLogo";
import MintBtn from "../../atoms/mint-btn/MintBtn";
import DrawerMenu from "../../molecules/drawer-menu/DrawerMenu";
import NavbarList from "../../molecules/navbar-list/NavbarList";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-left">
          <div className="navbar-logo">
            <MFSLogo />
          </div>
          <div className="navlist-desk">
            <NavbarList />
          </div>
        </div>

        <div className="mint-desk">
          <MintBtn />
        </div>
        <div className="drawer-mobile">
          <DrawerMenu />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
