import React from "react";
import hara1 from "../../../assets/images/hara-image-1.png";
import hara2 from "../../../assets/images/hara-image-2.png";

const HaraMobile = () => {
  return (
    <div className="hara-mobile">
      <div className="hara-mobile-container">
        <div className="hara-mobile-info">
          <h1 className="hara-title">Our Social Impact Progress</h1>
          <span>
            We deliver 20% from Primary & Secondary Sales of the NFTs to Hara
            Foundation. The initiative is to make a social impact by
            distributing Ginger Seeds to empower Women Farmers in Indonesia.
            They can plant & harvest the Ginger to bring extra income for their
            families. Imagine how much impact we can create together!
          </span>

          <div className="hara-mobile-photos">
            <div className="hara1-mobile-img">
              <img src={hara1} alt="Hara" />
            </div>
            <div className="hara2-mobile-img">
              <img src={hara2} alt="Hara" />
            </div>
          </div>

          <div className="hara-mobile-middle">
            <h1>11.7 $ETH</h1>
            <span style={{ marginTop: "5px" }}>
              Total Value converted to Ginger Seeds
            </span>
            <div className="separate-mobile"></div>
            <h1>200,000</h1>
            <span className="span-unit">Polybags</span>
            <span style={{ marginTop: "5px" }}>
              Ginger Seeds Purchased from our Presale phase in December 2021
            </span>
            <div className="separate-mobile"></div>
            <h1>50,000</h1>
            <span className="span-unit">Polybags / Month</span>
            <span style={{ marginTop: "5px" }}>
              Monthly Ginger Seeds Distribution to Women Farmers
            </span>
          </div>
          <span>
            We work together with{" "}
            <a
              href="https://www.foundation.hara.ag/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#E2FBA6",
                cursor: "pointer",
              }}
            >
              Hara Foundation
            </a>{" "}
            to distribute and supervise the Ginger Seeds planting and
            harvesting.
          </span>
        </div>
      </div>
    </div>
  );
};

export default HaraMobile;
