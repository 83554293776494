import React from "react";
import fcreaturepack from "../../../assets/images/forest-creature-pack.png";
import fcreaturepackm from "../../../assets/images/forest-creature-pack-mobile.png";

const ForestCreatures = () => {
  return (
    <div className="forest-creatures">
      <div className="forest-container">
        <div className="forest-caption">
          <h1>Summon Forest Creatures</h1>
          <span>
            The Forest Creatures can help your journey in Meta Forest Society.
            However, you must trade your $HART with The Tomes of Eldar to summon
            the Forest Creatures you need. Are you ready?
          </span>
        </div>
        <div className="forest-ilustration">
          <img src={fcreaturepack} alt="Forest Creature" />
        </div>
        <div className="forest-ilustration-mobile">
          <img src={fcreaturepackm} alt="Forest Creature" />
        </div>
      </div>
    </div>
  );
};

export default ForestCreatures;
