import React from "react";
import MFSLogo from "../../atoms/mfs-logo/MFSLogo";
import TOSBtn from "../../atoms/term-of-service-btn/TOSBtn";
import SosmedFooter from "../../molecules/sosmed-button/SosmedFooter";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <div className="footer-logo">
            <MFSLogo />
          </div>
          <TOSBtn />
        </div>

        <div className="copyright">
          <span>&copy; 2021 Meta Forest Society. All Rights Reserved.</span>
        </div>
        <div className="footer-right">
          <SosmedFooter />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
