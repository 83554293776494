// import { useState } from "react";
import { Flex } from "@chakra-ui/layout";
import {
  // Popover,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverBody,
  // PopoverArrow,
  Image,
} from "@chakra-ui/react";
// import { CopyIcon, CheckCircleIcon } from "@chakra-ui/icons";
// import { useClipboard } from "@chakra-ui/hooks";
// import discordlogo from "../../../assets/logo/discord-logo.svg";
import twitterlogo from "../../../assets/logo/twitter-logo.svg";
import instagramlogo from "../../../assets/logo/instagram-logo.svg";

const SocmedTeams = ({ data, propsStyle = {} }) => {
  // const [discordUname, setDiscordUname] = useState("");
  // const { hasCopied, onCopy } = useClipboard(discordUname);

  return (
    <Flex gridGap="3" pt="3" cursor="pointer" style={{ ...propsStyle }}>
      {data.map((dataSocmed, index) => {
        return (
          <Flex key={index + "aazz"}>
            {dataSocmed.name === "twitter" && (
              <Flex
                justify="center"
                w="36px"
                h="36px"
                bgColor="#724797"
                borderRadius="full"
                padding="2"
                _hover={{ transform: "scale(1.1)" }}
              >
                <a
                  href={dataSocmed.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image alt="twitter" src={twitterlogo} width={28} />
                </a>
              </Flex>
            )}
            {dataSocmed.name === "instagram" && (
              <Flex
                justify="center"
                w="36px"
                h="36px"
                bgColor="#724797"
                borderRadius="full"
                padding="2"
                justifyContent="center"
                alignItems="center"
                _hover={{ transform: "scale(1.1)" }}
              >
                <a
                  href={dataSocmed.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image alt="instagram" src={instagramlogo} width={28} />
                </a>
              </Flex>
            )}

            {/* {dataSocmed.name === "discord" && (
              <Popover autoFocus={false} key={index + "ssdd"}>
                <PopoverTrigger>
                  <Flex
                    justify="center"
                    w="36px"
                    h="36px"
                    bgColor="#724797"
                    borderRadius="full"
                    padding="2"
                    _hover={{ transform: "scale(1.1)" }}
                    onClick={() => setDiscordUname(dataSocmed?.link)}
                  >
                    <Image alt="discord" src={discordlogo} width={28} />
                  </Flex>
                </PopoverTrigger>
                <PopoverContent
                  maxW="max-content"
                  color="#F5F5F5"
                  bgColor="rgba(37, 34, 56, 0.86)"
                  display="flex"
                  align="center"
                  cursor="text"
                >
                  <PopoverArrow />
                  <PopoverBody fontSize="xs">
                    {dataSocmed.link}
                    {hasCopied ? (
                      <CheckCircleIcon w={3} h={3} ml="2" cursor="pointer" />
                    ) : (
                      <CopyIcon
                        w={3}
                        h={3}
                        ml="2"
                        cursor="pointer"
                        onClick={onCopy}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )} */}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default SocmedTeams;
