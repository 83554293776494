import React from "react";
import AccordionFilter from "../../molecules/accordion/AccordionFilter";
import ElvesSwitch from "../../molecules/elves-switch/ElvesSwitch";
import { Spin } from "antd";

const FilterBox = ({
  showFemale,
  traits,
  handleChangeParamsFemale,
  handleChangeParamsFilter,
  showFilter,
  loading,
}) => {
  return (
    <div className="filter-box">
      <div className="filter-box__container">
        <ElvesSwitch
          showFemale={showFemale}
          handleChangeParamsFemale={handleChangeParamsFemale}
        />
        {loading ? (
          <div className="loading-indicator">
            <Spin wrapperClassName="spinner" />
          </div>
        ) : (
          <>
            <AccordionFilter
              traits={traits}
              handleChangeParamsFilter={handleChangeParamsFilter}
              showFilter={showFilter}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FilterBox;
