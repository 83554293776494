import React from "react";
import SosmedButton from "../../molecules/sosmed-button/SosmedButton";

const MINTING_URL = process.env.REACT_APP_MINTING_URL;

const Hero = () => {
  return (
    <div className="hero" id="home">
      <div className="hero-content">
        <div>
          <h1 className="hero-title">
            Join Our Adventure & Create Social Impact Together
          </h1>
          <div className="hero-btn">
            <a href={MINTING_URL} rel="noopener noreferrer" target="_blank">
              Buy on OpenSea
            </a>
          </div>
          <h1 className="hero-title-mobile">
            Come Join Us On Our Adventures & Bring Social Impact Together
          </h1>
        </div>

        <div className="hero-sosmed">
          <SosmedButton />
        </div>
      </div>
    </div>
  );
};

export default Hero;
