import { ActionTypes } from "../constants/actionTypes";
import { allElves, elfDetail } from "../../services";
// import { message } from "antd"

// get elves gallery
export const getElvesAsync = (isFemale, isDsc, page, per_page, filter, find) => {  // Get Elves Action
  return async (dispatch) => {
    dispatch(getElves())
    try {
      const res = await allElves(isFemale, isDsc, page, per_page, filter, find)

      dispatch(getElvesSuccess(res.data))
    } catch (error) {
      dispatch(getElvesFailed(error))
    }
  }
}


export const getElves = () => ({
  type: ActionTypes.GET_ELVES_GALLERY
})

export const getElvesSuccess = (elves) => {
  return {
    type: ActionTypes.GET_ELVES_GALLERY_SUCCESS,
    payload: elves
  }
}

export const getElvesFailed = (error) => {
  return {
    type: ActionTypes.GET_ELVES_GALLERY_FAILED,
    payload: error.response.data.error
  }
}

// get elf Detail
export const getElfDetailAsync = (asset_contract_address, token_id) => {  // Get Elves Action
  return async (dispatch) => {
    dispatch(getElfDetail())
    try {
      const res = await elfDetail(asset_contract_address, token_id)

      dispatch(getElfDetailSuccess(res.data))
    } catch (error) {
      dispatch(getElfDetailFailed(error))
    }
  }
}


export const getElfDetail = () => ({
  type: ActionTypes.GET_ELF_DETAIL
})

export const getElfDetailSuccess = (elf) => {
  return {
    type: ActionTypes.GET_ELF_DETAIL_SUCCESS,
    payload: elf
  }
}

export const getElfDetailFailed = (error) => {
  return {
    type: ActionTypes.GET_ELF_DETAIL_FAILED,
    payload: error
  }
}

export const clearElfDetail = (elf) => {
  return {
    type: ActionTypes.CLEAR_ELF_DETAIL,
    payload: elf
  }
}