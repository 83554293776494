import React from "react";

const Kolase = () => {
  return (
    <div className="kolase">
      <div className="kolase-bg">
        <div className="kolase-overlay-top"></div>
        <div className="kolase-overlay-bottom"></div>
      </div>
      <div className="kolase-content">
        <div className="kolase-caption">
          <h1>Brace Yourself for a Journey</h1>
          <h3>3636 Genesis Elves with 184 Traits.</h3>
          <span>
            Our roadmap includes Elven Quests (send your Elves to complete
            Quests & get $HART) and Trade $HART to get special items NFTs to
            upgrade your Elves or summon mysterious Forest Creatures.
          </span>
          <span>
            We also donate 20% of Primary & Secondary Sales to empower Women
            Farmers in Indonesia by providing Ginger Seeds.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Kolase;
