export const ActionTypes = {
  GET_ELVES_GALLERY: "GET_ELVES_GALLERY",
  GET_ELVES_GALLERY_SUCCESS: "GET_ELVES_GALLERY_SUCCESS",
  GET_ELVES_GALLERY_FAILED: "GET_ELVES_GALLERY_FAILED",
  CLEAR_ELVES_GALLERY: "CLEAR_ELVES_GALLERY",

  GET_ELF_DETAIL: "GET_ELF_DETAIL",
  GET_ELF_DETAIL_SUCCESS: "GET_ELF_DETAIL_SUCCESS",
  GET_ELF_DETAIL_FAILED: "GET_ELF_DETAIL_FAILED",
  CLEAR_ELF_DETAIL: "CLEAR_ELF_DETAIL",
}