import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  elves: {},
  elf: {},
  hasError: false
}

export const elvesReducer = (state = initialState, action) => {

  switch (action.type) {

    case ActionTypes.GET_ELVES_GALLERY: // get all male elves
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.GET_ELVES_GALLERY_SUCCESS:
      return {
        ...state,
        elves: action.payload,
        isLoading: false,
        hasError: false
      }
    case ActionTypes.GET_ELVES_GALLERY_FAILED:
      return {
        ...state,
        elves: {},
        isLoading: false,
        hasError: action.payload
      }
    case ActionTypes.CLEAR_ELVES_GALLERY:
      return {
        ...state,
        elves: {}
      }

    case ActionTypes.GET_ELF_DETAIL: // get all male elves
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.GET_ELF_DETAIL_SUCCESS:
      return {
        ...state,
        elf: action.payload,
        isLoading: false
      }
    case ActionTypes.GET_ELF_DETAIL_FAILED:
      return {
        ...state,
        elf: {},
        isLoading: false,
        hasError: action.hasError
      }
    case ActionTypes.CLEAR_ELF_DETAIL:
      return {
        ...state,
        elf: {}
      }

    default:
      return state;
  }
}