import React, { useEffect } from "react";
import Footer from "../components/organisms/footer/Footer";
import Navbar from "../components/organisms/navbar/Navbar";

const TermOfService = () => {
  useEffect(() => {
    sessionStorage.removeItem("MFS-page");
    sessionStorage.removeItem("MFS-sort");
  }, []);

  return (
    <div id="term-of-service">
      <Navbar />
      <div className="tos">
        <div className="tos-container">
          <h1>Terms of Service</h1>
          <div className="tos-paragraph">
            <h2>TERMS OF SERVICE</h2>
            <span>
              Meta Forest Society is a collection of 3,636 Genesis Elves NFTs
              that gives 20% from Primary & Secondary Sales to provide Ginger
              Seeds to empower Women Farmers in Indonesia. Stake your Genesis
              Elves NFTs to earn $HART (Hara Token) daily.{" "}
              <span>
                Find out more about Meta Forest Society and Hara Token utilities
                in our{" "}
                <a
                  href="https://discord.gg/metaforestsociety"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#E2FBA6",
                    cursor: "pointer",
                    zIndex: 100,
                  }}
                >
                  discord
                </a>
                .
              </span>
            </span>
            <span>
              Users are entirely responsible for the safety and management of
              their own private ETH wallets and validating all transactions and
              contracts generated by this website before approval. Furthermore,
              as Meta Forest Society smart contract runs on the Ethereum
              network, there is no ability to undo, reverse, or restore any
              transactions. By using this website you are accepting sole
              responsibility for any and all transactions involving our digital
              collectibles this includes gas fees, failed transactions or any
              issues that may arise from interacting on the Ethereum network.
            </span>
          </div>

          <div className="tos-paragraph">
            <h2>TERMS OF USE</h2>
            <span>
              Non-fungible tokens are not an investment. All Meta Forest Society
              NFTs are just collectibles passed on the Ethereum network and
              should be treated as such. Further development is determined by
              user interest, adoption, and social adoption. You, the owner,
              agree that your purchase of this NFT collection is just as a
              collectible. You understand that they have no inherent monetary
              value, and they should be treated as nothing more than a
              collectible.
            </span>
            <span>
              By minting Meta Forest Society from our website or the smart
              contract; you acknowledge that you have read, and do hereby accept
              the terms and conditions listed above.
            </span>
            <span>
              Terms of use may be updated without notice at any moment, however,
              all changes will be updated on our Discord for convenience and
              ease. As the project advances the terms may be subject to
              modification at any moment.
            </span>
            <span>
              You agree to any ongoing changes to our policy (excluding NFT
              ownership and proprietary use) as a participant of the Meta Forest
              Society mint, private sale, after market, and giveaways.
            </span>
          </div>

          <div className="tos-paragraph">
            <h2>IP RIGHTS</h2>
            <span>
              You Own the NFT. Each NFT from our collection belongs to the
              person who holds it in their wallet. Upon minting the ownership of
              the NFT is transferred to you which is mediated specifically by
              the Smart Contract and the Ethereum Network: at no point may we
              seize, freeze, or otherwise modify the ownership of any Meta
              Forest Society art piece. Every piece is unique, however, some are
              more rare than others.
            </span>
            <span>
              Subject to the legal jurisdiction in which you abide, Meta Forest
              Society grants you a worldwide, royalty-free license to personal
              and commercial use, copy, and display the purchased art, along
              with any extensions that you choose to create or use, solely for
              the following purposes: (i) for your own personal, non-commercial
              use. As part of a marketplace that permits the purchase and sale
              of your D.A. / NFT, provided that the marketplace
              cryptographically verifies each Meta Forest Society owner’s rights
              to display the Art freely. to ensure that only the actual owner
              can display the Art. Commercial Use just like personal use is.
            </span>
            <span>
              You can not use the Meta Forest Society logo for commercial use.
              Please contact us if you want to explore partnership with us.
            </span>
            <span>
              Owning a Meta Forest Society collectible NFT does not grant you
              the rights to duplicate, steal, remake, or redo any of the
              mechanics or artwork associated with Meta Forest Society.
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermOfService;
