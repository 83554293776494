import React from "react";
import ReactPaginate from "react-paginate";
import nextButton from "../../../assets/logo/next-button.svg";

const Pagination = ({ elves, handleChangeParamsPage, showPage, isPage }) => {
  // let handleForcePage = showPage && showPage.length !== 0 ? showPage - 1 : 0;

  return (
    <div className="pagination">
      <ReactPaginate
        previousLabel={
          <div className="pagination--prev">
            <img src={nextButton} alt="" />
            Prev
          </div>
        }
        nextLabel={
          <div className="pagination--next">
            Next
            <img src={nextButton} alt="" />
          </div>
        }
        pageCount={elves?.result?.total_pages}
        onPageChange={handleChangeParamsPage}
        marginPagesDisplayed={0}
        pageRangeDisplayed={window.innerWidth <= 992 ? 2 : 4}
        containerClassName="pagination__container"
        pageClassName="pagination__list"
        previousClassName="pagination__prev"
        nextClassName="pagination__next"
        activeLinkClassName="pagination__list--active"
        breakClassName="pagination__break"
        disabledClassName="pagination--disabled"
        forcePage={isPage - 1}
      />
    </div>
  );
};

export default Pagination;
