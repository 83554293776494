import React from "react";
import ElvesInitiator from "../../molecules/elves-initiator/ElvesInitiator";
import ElvesTeam from "../../molecules/elves-team/ElvesTeam";

const ElvenTeam = () => {
  return (
    <div className="elven-team">
      <div className="team-container">
        <div className="team">
          <h1>The Elves Initiator</h1>
          <ElvesInitiator />
        </div>
        <div className="team">
          <h1>Our Team</h1>
          <ElvesTeam />
        </div>
      </div>
    </div>
  );
};

export default ElvenTeam;
