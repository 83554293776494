import React from "react";

const MINTING_URL = process.env.REACT_APP_MINTING_URL;

const MintBtn = () => {
  return (
    <div>
      <a
        href={MINTING_URL}
        rel="noopener noreferrer"
        target="_blank"
        className="mint-btn"
      >
        Buy on OpenSea
      </a>
    </div>
  );
};

export default MintBtn;
