import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ElvesGallery from "../components/organisms/elves-gallery/ElvesGallery";
import FilterBox from "../components/organisms/filter-box/FilterBox";
import Navbar from "../components/organisms/navbar/Navbar";
import { getElvesAsync } from "../redux/actions/elvesAction";
import { elvesContract, getTraits } from "../services";
import { useSearchParams } from "react-router-dom";
import { paramsToObjects } from "../utils/paramsToObjects";
import Footer from "../components/organisms/footer/Footer";
import MFSLoading from "../components/molecules/loading/MFSLoading";

const BookOfElves = () => {
  const pageStore = sessionStorage.getItem("MFS-page")
    ? sessionStorage.getItem("MFS-page")
    : 1;
  const sortStore = sessionStorage.getItem("MFS-sort")
    ? sessionStorage.getItem("MFS-sort")
    : "true";
  const dispatch = useDispatch();
  const [isDsc, setIsDsc] = useState(true);
  const [traits, setTraits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [isSort, setIsSort] = useState(sortStore);
  const [isPage, setIsPage] = useState(pageStore);
  const [contract, setContract] = useState(null);
  const showActiveElf = searchParams.get("search") || "";
  // const showSort = searchParams.get("isDsc") || true;
  const showFemale = searchParams.get("isFemale") || false;
  const showPage = searchParams.get("page") || 1;
  const per_page = 9;
  const filterParams = searchParams.getAll("filter") || [];
  // const convertFilter = showFilter.map((item) => item.split(","));
  const showFilter = filterParams.map((filter) => filter.split(","));

  // Protect value showFemale
  const checkShowFemale =
    showFemale === "true" || showFemale === "false" || showFemale === false
      ? showFemale
      : false;

  // Function for handling empty string Filter Traits
  const getConvert = (showFilter) => {
    let store = [];
    const isEmpty = (store) => {
      return (
        Array.isArray(store) && (store.length === 0 || store.every(isEmpty))
      );
    };
    for (let i = 0; i < showFilter?.length; i++) {
      if (showFilter[i]?.includes("")) {
        store.push([]);
      } else {
        store.push(showFilter[i]);
      }
    }

    if (isEmpty(store) === true) {
      let onClear = [];
      let paramsObj = paramsToObjects(searchParams);
      setSearchParams({
        ...paramsObj,
        filter: onClear,
      });
    }
    return store;
  };

  let find = showActiveElf && [showActiveElf];

  const { elves, isLoading, hasError } = useSelector(
    (state) => state.elvesReducer
  );

  // Contract for female or male condition
  const genderContract =
    showFemale !== "true"
      ? contract?.contractAddrMale
      : contract?.contractAddrFemale;

  useEffect(() => {
    sessionStorage.setItem("MFS-page", isPage);
    sessionStorage.setItem("MFS-sort", isSort);
    dispatch(
      getElvesAsync(
        JSON.parse(checkShowFemale),
        JSON.parse(isSort),
        isPage,
        per_page,
        getConvert(showFilter),
        find
      )
    );

    return () => {
      setIsDsc(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isSort, isPage]);

  useEffect(() => {
    setLoading(true);

    const getContract = async () => {
      const res = await elvesContract();
      setContract(res.data);

      if (res && contract) {
        getTraits(genderContract).then((res) => {
          setTraits(
            res.data.map((trait) => {
              return { ...trait, checkValue: [] };
            })
          );
          setLoading(false);
        });
      }
    };
    // setLoading(false);
    getContract().catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFemale, genderContract]);

  // Handling Search Input
  const handleChangeParamsSearch = (e) => {
    let paramsObj = paramsToObjects(searchParams);
    setIsPage(1);
    sessionStorage.setItem("MFS-sort", true);
    setSearchParams({
      ...paramsObj,
      search: e.target.value,
    });
  };

  // Handling Sort DSC or ASC
  const handleChangeParamsSort = (value) => {
    // let onClear = [];
    // let paramsObj = paramsToObjects(searchParams);
    // setSearchParams({
    //   ...paramsObj,
    //   isDsc: value,
    //   filter: onClear,
    // });
    setIsSort(value);
    setIsPage(1);
    sessionStorage.setItem("MFS-sort", value);
    sessionStorage.setItem("MFS-page", 1);
  };

  // Handling Switch Female or Male
  const handleChangeParamsFemale = (e) => {
    let onClear = [];
    let paramsObj = paramsToObjects(searchParams);
    setIsPage(1);
    sessionStorage.setItem("MFS-sort", true);
    sessionStorage.setItem("MFS-page", 1);
    setSearchParams({
      ...paramsObj,
      isFemale: e.target.value,
      filter: onClear,
    });
  };

  // Handling Pagination
  const handleChangeParamsPage = ({ selected }) => {
    // let paramsObj = paramsToObjects(searchParams);
    // setSearchParams({
    //   ...paramsObj,
    //   page: selected + 1,
    // });
    setIsPage(selected + 1);
    sessionStorage.setItem("MFS-sort", true);
  };

  // Handling Filter Traits
  function handleChangeParamsFilter(val, idx) {
    let result = [...showFilter];
    for (let i = 0; i <= idx; i++) {
      if (i === idx) {
        result[i] = val;
      }
      if (i !== idx) {
        if (result[i] === undefined) {
          result[i] = [""];
        }
      }
    }
    setIsPage(1);
    sessionStorage.setItem("MFS-page", 1);
    let paramsObj = paramsToObjects(searchParams);
    setSearchParams({
      ...paramsObj,
      filter: result,
    });
    // console.log(val, idx);
    // setTraits((prevState) => {
    //   let newTrait = [...prevState];
    //   newTrait[idx].checkValue = val;
    //   return newTrait;
    // });
  }

  // Handling Clear All Filter Traits
  const handleClear = () => {
    let onClear = [];
    let paramsObj = paramsToObjects(searchParams);
    setIsPage(1);
    sessionStorage.setItem("MFS-page", 1);
    setSearchParams({
      ...paramsObj,
      filter: onClear,
    });
  };

  // Handling Uncheck/delete Filter Traits Single
  const handleDelete = (e) => {
    setIsPage(1);
    sessionStorage.setItem("MFS-page", 1);
    let paramsObj = paramsToObjects(searchParams);
    for (let i = 0; i < getConvert(showFilter).length; i++) {
      for (let j = 0; j < getConvert(showFilter)[i].length; j++) {
        if (getConvert(showFilter)[i][j] === e.target.innerText) {
          getConvert(showFilter)[i].splice(j, 1);
          setSearchParams({
            ...paramsObj,
            filter: getConvert(showFilter),
          });
        }
      }
    }
  };

  // Loading Condition
  if (
    hasError !== "TypeError: Cannot read property 'os_data' of undefined" &&
    Object.keys(elves).length === 0
  )
    return <MFSLoading />;

  return (
    <div className="book-of-elves">
      <div className="book-of-elves__bg" />
      <Navbar />
      <main className="book-of-elves__container">
        <h1 className="book-of-elves__title">Book of Elves</h1>
        <div className="book-of-elves__content">
          <FilterBox
            showFemale={checkShowFemale}
            traits={traits}
            handleChangeParamsFemale={handleChangeParamsFemale}
            handleChangeParamsFilter={handleChangeParamsFilter}
            showFilter={showFilter}
            loading={loading}
          />
          <ElvesGallery
            elves={elves}
            genderContract={genderContract}
            handleChangeParamsSearch={handleChangeParamsSearch}
            handleChangeParamsSort={handleChangeParamsSort}
            isDsc={isDsc}
            showFilter={showFilter}
            handleChangeParamsPage={handleChangeParamsPage}
            handleClear={handleClear}
            handleDelete={handleDelete}
            showPage={showPage}
            showFemale={checkShowFemale}
            handleChangeParamsFemale={handleChangeParamsFemale}
            traits={traits}
            handleChangeParamsFilter={handleChangeParamsFilter}
            isLoading={isLoading}
            hasError={hasError}
            isPage={isPage}
            isSort={isSort}
            contract={contract}
            showActiveElf={showActiveElf}
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default BookOfElves;
