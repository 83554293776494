import React from "react";
import { Link } from "react-router-dom";

const TOSBtn = () => {
  return (
    <div>
      <Link to="/term-of-service" className="tos-btn">
        Terms of Service
      </Link>
    </div>
  );
};

export default TOSBtn;
