import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Navbar from "../components/organisms/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import Footer from "../components/organisms/footer/Footer";
import { elfPair, elvesContract } from "../services";
import openseaColored from "../assets/logo/opensea-logo-color.svg";
import backButton from "../assets/logo/back-button.svg";
import checkIndicator from "../assets/logo/check-circle-outline.svg";
import MFSLoading from "../components/molecules/loading/MFSLoading";
import PageNotFound from "./PageNotFound";

const ElvesDetail = () => {
  const [pair, setPair] = useState({});
  const [contract, setContract] = useState(null);
  const { genderParam, token_id } = useParams();
  const [isError, setIsError] = useState(null);
  const navigate = useNavigate();
  const openseaurl = process.env.REACT_APP_OPENSEA;

  // Contract for female or male condition
  const asset_contract_address =
    genderParam === "female"
      ? contract?.contractAddrFemale
      : contract?.contractAddrMale;

  // const { elf } = useSelector((state) => state.elvesReducer);

  useEffect(() => {
    const getContract = async () => {
      try {
        const res = await elvesContract();
        setContract(res.data);

        if (res && contract) {
          elfPair(asset_contract_address, [token_id])
            .then((res) => setPair(res.data))
            .catch((err) => {
              setIsError(err.response.data.error);
            });
        }
      } catch (err) {
        console.log(err);
      }
    };

    getContract();

    return () => {
      setPair({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset_contract_address]);

  if (
    isError !== "TypeError: Cannot read property 'os_data' of undefined" &&
    Object.keys(pair).length === 0
  )
    return <MFSLoading />;
  // Object.keys(pair).length === 0 ? <MFSLoading /> : <PageNotFound />;

  return (
    <div className="elves-detail">
      {isError ? (
        <PageNotFound />
      ) : (
        <>
          <div className="elves-detail__bg" />
          <Navbar />
          {pair?.data?.map((elf) => {
            return (
              <main
                key={elf.main_data.tokenId}
                className="elves-detail__container"
              >
                <span
                  onClick={() => navigate(-1)}
                  className="elves-detail__back"
                >
                  <img src={backButton} alt="" /> Elf Details
                </span>
                <div className="elves-detail__content">
                  {/* Left Section */}
                  <div className="elves-detail__left">
                    {elf.main_data.image?.includes(".mp4") ? (
                      <video className="elves-detail__img" loop autoPlay>
                        <source src={elf.main_data.image} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={elf.main_data.image}
                        alt=""
                        className="elves-detail__img"
                      />
                    )}

                    {/* Have pair female or not */}
                    {elf?.pair_data?.tokenId !== 0 &&
                    pair?.isFemale === false ? (
                      <div className="elves-detail__female">
                        <div className="elves-detail__female-container">
                          {elf?.pair_data?.image_pair?.includes(".mp4") ? (
                            <video className="elves-detail__pair" loop autoPlay>
                              <source
                                src={elf.pair_data.image_pair}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={elf.pair_data.image_pair}
                              alt=""
                              className="elves-detail__pair"
                            />
                          )}

                          <div className="elves-detail__female-info">
                            <h2 className="elves-detail__female-info-title">
                              #{elf?.pair_data?.tokenId}
                            </h2>
                            <div>
                              <span>Female Elf Claimed</span>
                              <img src={checkIndicator} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : elf?.pair_data?.tokenId !== 0 &&
                      pair?.isFemale === true ? (
                      <div className="elves-detail__female">
                        <div className="elves-detail__female-container">
                          {elf?.pair_data?.image_pair?.includes(".mp4") ? (
                            <video className="elves-detail__pair" loop autoPlay>
                              <source
                                src={elf.pair_data.image_pair}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={elf.pair_data.image}
                              alt=""
                              className="elves-detail__pair"
                            />
                          )}

                          <div className="elves-detail__female-info">
                            <h2 className="elves-detail__female-info-title">
                              #{elf?.pair_data?.tokenId}
                            </h2>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* Right Section */}
                  <div className="elves-detail__right">
                    <h2>#{elf.main_data.tokenId}</h2>
                    <div className="elves-detail__line" />
                    <div className="elves-detail__owner">
                      <span>
                        Owned by{" "}
                        <strong>
                          {elf?.main_data.owner?.slice(2, 8)?.toUpperCase()}
                        </strong>
                      </span>

                      {/* Link ke asset marketplace */}
                      <div className="elves-detail__link">
                        <a
                          href={`${openseaurl}assets/${asset_contract_address}/${elf.main_data.tokenId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={openseaColored} alt="Opensea" />
                        </a>
                      </div>
                    </div>

                    {/* Description */}
                    <div className="elves-detail__desc">
                      <span>Description</span>
                      <span>{elf.main_data.os_data.description}</span>
                    </div>

                    {elf?.main_data?.os_data?.traits?.length !== 0 ? (
                      <div className="elves-detail__traits">
                        <span className="elves-detail__traits-title">
                          Traits
                        </span>
                        <div className="elves-detail__traits-list">
                          {elf?.main_data?.os_data?.traits?.map(
                            (trait, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="elves-detail__traits-card"
                                >
                                  <div className="elves-detail__traits-card-container">
                                    <span>{trait.trait_type}</span>
                                    <span>{trait.value}</span>
                                    <span>
                                      {Math.round(trait?.percentage)}% Have This
                                      Trait
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </main>
            );
          })}

          <Footer />
        </>
      )}
    </div>
  );
};

export default ElvesDetail;
