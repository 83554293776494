import React from "react";
import mfslogo from "../../../assets/logo/logo-mfs.svg";

const MFSLogo = () => {
  return (
    <div>
      <img src={mfslogo} alt="Metaforest Society" className="mfs-logo" />
    </div>
  );
};

export default MFSLogo;
