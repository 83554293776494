import React from "react";
import { Collapse, Checkbox } from "antd";

const { Panel } = Collapse;
// const { Option } = Select;

const AccordionFilter = ({ traits, handleChangeParamsFilter, showFilter }) => {
  return (
    <>
      {traits?.length !== 0 ? (
        <aside className="accordion-filter">
          <div className="accordion-filter__title">Traits</div>
          <Collapse
            bordered={false}
            expandIconPosition="right"
            className="accordion-filter__accordion"
          >
            {traits?.map((trait, idx) => {
              return (
                <Panel
                  header={`${trait.trait_type}  (${trait.trait_value.length})`}
                  key={idx}
                >
                  <Checkbox.Group
                    options={trait.trait_value.map((item) => ({
                      label: `${item.value} (${item.count})`,
                      // value: `${item.value}##${item.type}`,
                      value: `${item.type}: ${item.value}`,
                    }))}
                    onChange={(val) => handleChangeParamsFilter(val, idx)}
                    value={showFilter[idx]}
                    // value={showFilter}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </aside>
      ) : (
        ""
      )}
    </>
  );
};

export default AccordionFilter;

// {trait?.trait_value?.map((item, idx) => {
//   return (
//     <Checkbox
//       key={idx}
//       onChange={onChange}
//       style={{ color: "white" }}
//     >
//       <div className="accordion-filter__item">
//         <div>{item.value}</div>
//         <div>{item.count}</div>
//       </div>
//     </Checkbox>
//   );
// })}
