import React from "react";
import { Spin } from "antd";
import mfsLogo from "../../../assets/logo/logo-mfs.svg";

const MFSLoading = () => {
  return (
    <div className="spinner-container">
      <img src={mfsLogo} alt="" className="mfs-loading" />
      <Spin size="large" wrapperClassName="spinner" />
    </div>
  );
};

export default MFSLoading;
