import LandingPage from './pages/LandingPage';
import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import './styles/main.scss'
import './App.css'
import "swiper/css";
import "swiper/css/bundle"
import 'antd/dist/antd.css'
import TermOfService from './pages/TermOfService';
import ScrollToTop from './components/atoms/scroll-to-top/ScrollToTop';
import BookOfElves from './pages/BookOfElves';
import ElvesDetail from './pages/ElvesDetail';
import PageNotFound from './pages/PageNotFound';

function App() {

  return (
    <div className="App">
      <ChakraProvider>
        <ScrollToTop>
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/term-of-service" element={<TermOfService />} />
            <Route exact path="/book-of-elves" element={<BookOfElves />} />
            <Route exact path="/book-of-elves/:genderParam/:token_id" element={<ElvesDetail />} />
          </Routes>
        </ScrollToTop>
      </ChakraProvider>
    </div>
  );
}

export default App;
