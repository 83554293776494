import React from "react";
import ElvenSlideInitiator from "../../molecules/elven-slide/ElvenSlideInitiator";
import ElvenSlideTeam from "../../molecules/elven-slide/ElvenSlideTeam";

const ElvenSlide = () => {
  return (
    <div className="elvenslide-team">
      <div className="teamslide-container">
        <div className="slide-team">
          <h1>The Elves Initiator</h1>
          <ElvenSlideInitiator />
        </div>
        <div className="slide-team">
          <h1>Our Team</h1>
          <ElvenSlideTeam />
        </div>
      </div>
    </div>
  );
};

export default ElvenSlide;
