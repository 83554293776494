import React from "react";
import hara1 from "../../../assets/images/hara-image-1.png";
import hara2 from "../../../assets/images/hara-image-2.png";

const Hara = () => {
  return (
    <div className="hara">
      <div className="hara-container">
        <div className="hara-info">
          <h1 className="hara-title">Social Impact Progress</h1>
          <span>
            We deliver 20% from Primary & Secondary Sales of the NFTs to Hara
            Foundation. The initiative is to make a social impact by
            distributing Ginger Seeds to empower Women Farmers in Indonesia.
            They can plant & harvest the Ginger to bring extra income for their
            families. Imagine how much impact we can create together!
          </span>
          <div className="hara-middle">
            <h1>11.7 $ETH</h1>
            <span>Total Value converted to Ginger Seeds</span>
            <div className="separate"></div>
            <h1>
              200,000 <span>Polybags</span>
            </h1>
            <span>
              Ginger Seeds Purchased from our Presale phase in December 2021
            </span>
            <div className="separate"></div>
            <h1>
              50,000 <span>Polybags / Month</span>
            </h1>
            <span>Monthly Ginger Seeds Distribution to Women Farmers</span>
          </div>
          <span>
            We work together with{" "}
            <a
              href="https://www.foundation.hara.ag/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#E2FBA6",
                cursor: "pointer",
              }}
            >
              Hara Foundation
            </a>{" "}
            to distribute and supervise the Ginger Seeds planting and
            harvesting.
          </span>
        </div>
        <div className="hara-photos">
          <div className="hara1-img">
            <img src={hara1} alt="Hara" />
          </div>
          <div className="hara2-img">
            <img src={hara2} alt="Hara" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hara;
