import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import SocmedTeams from "../sosmed-team/SocmedTeams";

SwiperCore.use([Pagination]);

const ElvenSlideInitiator = () => {
  const advisorList = [
    {
      image: "/images/pfp_regi-w.png",
      name: "Regi Wahyu",
      desc: [
        "Regi Wahyu is a business developer who has experienced many different multinational companies in Indonesia and Asia Pacific.",
        "Continue his entrepreneurship journey by founding Dattabot in 2015 and Hara - Blockchain based company in 2018.",
      ],
      socmed: [
        { name: "twitter", link: "https://twitter.com/regiwahyu " },
        { name: "instagram", link: "https://www.instagram.com/regiwahyu" },
      ],
    },
    {
      image: "/images/pfp-richard.png",
      name: "Richard Fang",
      desc: [
        "Richard has over a decade of experience in UI/UX Design, Tech Startup, Marketing & building a business from the ground up. Now with Blockchain, Web3, and NFTs, he believes that this is the key for creators to spread their work to the world.",
      ],
      socmed: [
        { name: "twitter", link: "https://twitter.com/madeforyou" },
        { name: "instagram", link: "https://www.instagram.com/richardfang" },
      ],
    },
    {
      image: "/images/pfp_imron-z.png",
      name: "Imron Zuhri",
      desc: [
        "Imron Zuhri has more than 30 years of experience in technology and computer science, he is the founder and CTO of Dattabot and Hara. Aside from artificial general intelligence and blockchain, his other interest is also in democratic education and building art ecosystem.",
      ],
      socmed: [],
    },
  ];

  return (
    <Swiper
      pagination={true}
      className="mySwiper"
      style={{ height: "100%", width: "100vw" }}
    >
      {advisorList.map((data, index) => (
        <SwiperSlide key={index + data} className="elvenbox">
          <img src={data.image} alt={data.name} className="elvenpic" />
          <h3>{data.name}</h3>
          {data.desc.map((jd, index) => (
            <div key={index} style={{ marginTop: "4px" }} className="elvendesc">
              {jd}
            </div>
          ))}
          <SocmedTeams data={data?.socmed} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ElvenSlideInitiator;
