import React from "react";
import { chakra } from "@chakra-ui/system";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";

const AccordionFAQ = () => {
  return (
    <Accordion allowToggle color="#F5F5F5" className="accordion">
      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                border="none"
                background="#191437"
                cursor="pointer"
                color="#F5F5F5"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  What is NFT?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Box px="4">
                <Text pb="2" display="inline-block">
                  NFT stands for Non-fungible token. It's a digital asset that
                  is unique and can't be something else. NFTs are designed to
                  give you something that can’t be copied: ownership of the
                  work. You truly own the NFT that you minted or buy from
                  someone. Take it as a digital painting, that only you own the
                  painting. In short, NFT is a valuable digital asset that you
                  have full ownership of.
                </Text>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>

      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                border="none"
                background="#191437"
                cursor="pointer"
                color="#F5F5F5"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  What is Meta Forest Society?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Text px="4">
                <chakra.span pb="2" display="inline-block">
                  Meta Forest Society is a collection of 3636 elves NFT that
                  helps empower women farmers. We believe that NFT can be used
                  for social impact. Join our society to make an impact, empower
                  women farmers, and earn $HART through our staking program
                  while meeting like-minded people in the process.
                </chakra.span>
              </Text>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>

      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                border="none"
                background="#191437"
                cursor="pointer"
                color="#F5F5F5"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  What makes Meta Forest Society different?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Box px="4">
                <Text pb="2" display="inline-block">
                  Meta Forest Society (MFS) was initiated to help create
                  systemic change in the world through supporting the PERTAHARA
                  movement and other social impact movements in the future,
                  where our community can be interdependent on each other both
                  in the virtual world & physical world to support and empower
                  one another and create solutions to real world problems.
                </Text>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>

      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                border="none"
                background="#191437"
                cursor="pointer"
                color="#F5F5F5"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  How do I join the community?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Box px="4">
                <Text pb="2" display="inline-block">
                  You can start by following our twitter, and joining our
                  discord. Most events will be held on twitter, but if you wanna
                  have a chat with the community you can join the{" "}
                  <a
                    href="https://discord.gg/metaforestsociety"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#E2FBA6",
                      cursor: "pointer",
                    }}
                  >
                    discord
                  </a>
                  .
                </Text>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>

      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                border="none"
                background="#191437"
                cursor="pointer"
                color="#F5F5F5"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  What do I get by joining & owning the NFT?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Box px="4">
                <Text pb="2" display="inline-block">
                  Donation and valuable digital asset. By owning the MFS NFT,
                  you are helping empower women farmers by giving them a way to
                  increase their household income. Moreover, you have the full
                  ownership of the MFS NFT that you own.
                </Text>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>

      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                border="none"
                background="#191437"
                cursor="pointer"
                color="#F5F5F5"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  When mint?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Box px="4">
                <Text pb="2" display="inline-block">
                  We’ve already had a presale on 15th of December 2021. Since
                  then we have restructured our core team and decided to
                  relaunch the project.
                </Text>
                <Text>Priority Mint: TBA</Text>
                <Text pb="2">Public Mint: TBA</Text>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>

      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                border="none"
                background="#191437"
                cursor="pointer"
                color="#F5F5F5"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  Is there a presale spot?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Box px="4">
                <Text pb="2" display="inline-block">
                  Yes, since it’s a relaunch, we’re going to call it Priority
                  Mint. We’ll announce how to get a Priority Mint soon!
                </Text>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>

      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                border="none"
                background="#191437"
                cursor="pointer"
                color="#F5F5F5"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  I’m interested! What is the mint price ser?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Box px="4">
                <Text pb="2" display="inline-block">
                  Priority Mint: 0.048 ETH & Public Mint: 0.06 ETH
                </Text>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>

      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                border="none"
                background="#191437"
                cursor="pointer"
                color="#F5F5F5"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  How will you give back?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Box px="4">
                <chakra.span pb="2" display="inline-block">
                  <ol style={{ padding: "0 2rem" }}>
                    <li style={{ paddingBottom: ".75rem" }}>
                      20% from our NFT sales will be donated to the PERTAHARA
                      movement to empower small holder families where the
                      funneled amount will be used to purchase ginger seeds &
                      polybags for women farmers to start their ginger
                      plantation and create a 2-income household ecosystem.
                    </li>
                    <li>
                      As community is the biggest aspect MFS believes in, we
                      will give back to:
                      <ol type="a" style={{ paddingLeft: "1rem" }}>
                        <li>
                          Our NFT holders through giveaways and “free to mint”
                          female elves, as stated in our roadmap, to our OG
                          genesis holders.
                        </li>
                        <li>
                          <a
                            href="https://opensea.io/metaforestsociety-community"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#E2FBA6",
                              cursor: "pointer",
                            }}
                          >
                            The community wallet
                          </a>{" "}
                          will be used to support and purchase NFTs from other
                          social impact projects and women artists in the space
                          and MFS NFT holders will be able to vote and recommend
                          which projects MFS should support.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </chakra.span>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>

      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                border="none"
                background="#191437"
                cursor="pointer"
                color="#F5F5F5"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  What are your plans for the future?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Text px="4">
                <chakra.span pb="2" display="inline-block">
                  Aside from the features of the NFT, such as Elven Quests that
                  can yield $HART (Hara Token) and Shops where you can buy Items
                  to upgrade your Elves and summon Forest Creatures. We’re
                  planning to build Meta Forest Society on a metaverse platform
                  such as Sandbox. More information will be revealed as the
                  project progresses.
                </chakra.span>
              </Text>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>

      <AccordionItem borderTop="none" pt="4">
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton>
                <Box
                  flex="1"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="22"
                  className="faq-title"
                >
                  What is the smart contract address?
                </Box>
                {isExpanded ? (
                  <AiOutlineMinusCircle fontSize="20px" />
                ) : (
                  <AiOutlinePlusCircle fontSize="20px" />
                )}
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              <Box px="4">
                <chakra.span pb="2" display="inline-block">
                  You can see{" "}
                  <a
                    href="https://etherscan.io/address/0xb5e38715d5cc7a48984b7f8323bfe39d6d8e1e77"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#E2FBA6",
                      cursor: "pointer",
                    }}
                  >
                    our smart contract on Etherscan
                  </a>
                </chakra.span>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Box bg="#221C44" h="2px"></Box>
    </Accordion>
  );
};

export default AccordionFAQ;
