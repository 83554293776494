import React from "react";
import { InputNumber, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Link } from "react-router-dom";
import checkIcon from "../../../assets/logo/check-circle-outline.svg";
import Button from "../../atoms/button/Button";
import Pagination from "../../molecules/pagination/Pagination";
import DrawerFilter from "../../molecules/drawer-menu/DrawerFilter";
import xButtonRed from "../../../assets/logo/x-button-red.svg";

const { Option } = Select;

const ElvesGallery = ({
  elves,
  genderContract,
  handleChangeParamsSort,
  handleChangeParamsSearch,
  showFilter,
  handleChangeParamsPage,
  handleClear,
  handleDelete,
  showPage,
  showFemale,
  traits,
  handleChangeParamsFemale,
  handleChangeParamsFilter,
  isLoading,
  hasError,
  isPage,
  isSort,
  contract,
  showActiveElf,
}) => {
  const genderParam =
    genderContract === contract?.contractAddrMale ? "male" : "female";

  // console.log(genderContract, contract?.contractAddrMale);

  return (
    <div className="elves-gallery">
      {/* Search and Sort part */}
      <div className="elves-gallery__top">
        <InputNumber
          placeholder="Search by ID"
          className="elves-gallery__search"
          controls={false}
          type="number"
          value={showActiveElf === "" ? " " : showActiveElf}
          onPressEnter={(e) => handleChangeParamsSearch(e)}
        />
        <span className="elves-gallery__rank-out">Rank</span>
        <div className="elves-gallery__rank">
          <span className="elves-gallery__rank-title">Rank</span>
          <Select
            icon={<DownOutlined className="select__icon" />}
            defaultValue={isSort}
            className="elves-gallery__sort"
            onChange={handleChangeParamsSort}
            style={
              window.innerWidth <= 767 ? { width: "100%" } : { width: 130 }
            }
            dropdownClassName="dropClass"
          >
            <Option
              value="true"
              style={
                window.innerWidth <= 767
                  ? {
                      width: "100%",
                      backgroundColor: "#312653",
                      color: "white",
                    }
                  : { width: 130, backgroundColor: "#312653", color: "white" }
              }
            >
              High to Low
            </Option>
            <Option
              value="false"
              style={
                window.innerWidth <= 767
                  ? {
                      width: "100%",
                      backgroundColor: "#312653",
                      color: "white",
                    }
                  : { width: 130, backgroundColor: "#312653", color: "white" }
              }
            >
              Low to High
            </Option>
          </Select>
          <DrawerFilter
            showFemale={showFemale}
            handleChangeParamsFemale={handleChangeParamsFemale}
            traits={traits}
            handleChangeParamsFilter={handleChangeParamsFilter}
            showFilter={showFilter}
          />
          {/* <button className="elves-gallery__sort">Low to High</button> */}
        </div>
      </div>

      {/* Filter Indicator Part */}
      {showFilter?.length !== 0 ? (
        <div className="elves-gallery__filter">
          {showFilter &&
            showFilter?.map((item, idx) => {
              return item && item[0] === "" ? (
                ""
              ) : (
                <div key={idx} className="elves-gallery__filter-val">
                  {item?.map((val, i) => {
                    return (
                      <Button
                        key={i}
                        type="DARK"
                        className="elves-gallery__filter-val-item"
                        onClick={(e) => handleDelete(e)}
                      >
                        {val}
                        {/* <img src={xButton} alt="" /> */}
                      </Button>
                    );
                  })}
                </div>
              );
            })}
          <Button
            type="KILL"
            onClick={handleClear}
            className="elves-gallery__filter-clear"
          >
            Clear All
            <img src={xButtonRed} alt="" />
          </Button>
        </div>
      ) : (
        ""
      )}

      {/* Elves Gallery */}
      {isLoading ? (
        <div className="loading-indicator">
          <Spin size="large" wrapperClassName="spinner" />
        </div>
      ) : hasError ===
          "TypeError: Cannot read property 'os_data' of undefined" ||
        elves?.result?.data?.length === 0 ? (
        <div className="elves-gallery--empty">
          <span>Not Found</span>
        </div>
      ) : (
        <>
          <div className="elves-gallery__collection">
            {elves?.result?.data?.map((elf) => {
              return (
                <Link
                  to={`/book-of-elves/${genderParam}/${elf.tokenId}`}
                  key={elf.tokenId}
                  className="elves-gallery__card"
                >
                  {elf.image?.includes(".mp4") ? (
                    <video className="elves-gallery__image" loop autoPlay>
                      <source src={elf.image} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src={elf.image}
                      alt={elf.tokenId}
                      loading="lazy"
                      className="elves-gallery__image"
                    />
                  )}

                  <div className="elves-gallery__info">
                    <span>#{elf.tokenId}</span>
                    {elf.rank === 999999 ? (
                      <span>Unrank</span>
                    ) : (
                      <span>Rank {elf.rank}</span>
                    )}
                  </div>
                  {elf.pair !== 0 && genderParam === "male" ? (
                    <div className="elves-gallery__claimed">
                      <span>Female Elf Claimed</span>
                      <img src={checkIcon} alt="" />
                    </div>
                  ) : (
                    <div className="elves-gallery__noclaimed">
                      <span>Female Elf Claimed</span>
                      <img src={checkIcon} alt="" />
                    </div>
                  )}
                </Link>
              );
            })}
          </div>

          {/* Pagination */}
          {elves?.result?.total_pages !== 1 ? (
            <div className="elves-gallery__pagination">
              <Pagination
                elves={elves}
                handleChangeParamsPage={handleChangeParamsPage}
                showPage={showPage}
                isPage={isPage}
              />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default ElvesGallery;
